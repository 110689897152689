@import "abstracts/_abstracts";
.CookiesPopup {
    $block: &;
    position: fixed;
    padding: 2em;
    width: 48em;
    max-width: calc(100% - 1em);
    font-size: .875rem;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: var(--animationBase);
    z-index: var(--cookiePopupLayer, 1000);

    @media (max-width: 40rem) {
        padding: .5em;
    }

    &.is-opened,
    &.is-active {
        pointer-events: auto;
        opacity: 1;
        visibility: visible;
    }

    &,
    &-middle {
        inset: 50% auto auto 50%;
        transform: translateX(-50%) translateY(-50%);
    }

    &:not(&-middle) {
        transform: none;
    }

    &-left-top {
        inset: 0 auto auto 0;
    }

    &-left-bottom {
        inset: auto auto 0 0;
    }

    &-right-top {
        inset: 0 0 auto auto;
    }

    &-right-bottom {
        inset: auto 0 0 auto;
        justify-content: flex-end;
        align-items: flex-end;
    }

    &-inner {
        position: relative;
        display: grid;
        grid-template-rows: auto 1fr;
        max-height: 88vh;
        padding: 3.2em 4.8em 2em;
        background: #fff;
        transform: translateY(4em);
        transition: all 0.4s cubic-bezier(.64,-.19,.23,1.33);
        border: 1px solid currentColor;
        opacity: 0;

        @media (max-width: 40rem) {
            padding: 3.2em 1.5em 1.25em;
        }

        #{$block}.is-opened &,
        #{$block}.is-active & {
            opacity: 1;
            transform: translateY(0);
        }
    }

    form {
        display: flex;
        flex-flow: column;
        margin: 0 -1em;
        padding: 0 1em;
        overflow: hidden;
    }

    &-innerWrapper {
        flex: 1;
        margin-right: -.8em;
        padding-right: .8em;
        overflow: auto;
        margin-bottom: .25em;
    }

    &-title {
        font-size: 2.25em;
        padding-right: 2em;

        @media (max-width: 40rem) {
            font-size: 1.4em;
            padding-right: 0;
        }
    }

    &-text {
        line-height: 1.4;

        a {
            color: inherit;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    &-title {
        font-size: 2.25em;
        padding-right: 2em;

        @media (max-width: 40rem) {
            font-size: 1.4em;
            padding-right: 0;
        }
    }

    &-settings {
        margin: 1.25em 0 0;
    }

    &-type {
        background: #f5f5f5;

        &:not(:last-child) {
            margin-bottom: .75em;
        }

        &Header {
            display: flex;
            align-items: center;
            font-weight: bold;
            padding: .8em 1em;

            &::marker {
                display: none;
                content: none;
            }

            & > svg {
                width: 1em;
                height: 1em;
                transition: var(--animationBase);
                margin-left: 1.2em;
            }
        }

        summary::-webkit-details-marker {
            display:none;
        }

        &Title {
            line-height: 1.8;
            margin-right: auto;

            @media (max-width: 40rem) {
                font-size: .9em;
            }
        }

        &Status {
            color: #008000;

            @media (max-width: 40rem) {
                font-size: .8em;
            }

            &--disabled {
                color: #FF0000;
            }
        }

        &Content {
            padding: .4em 1em .8em;

            @media (max-width: 40rem) {
                font-size: .85em;
            }
        }

        &[open] &Header > svg {
            transform: rotate(180deg);
        }
    }

    &-buttonsWrapper {
        display: flex;
        flex-flow: row wrap;
        gap: .75em 1em;
        margin: var(--spaceLg) 0 var(--spaceSm);

        & > * {
            flex: 1 0 40%;
        }
    }

    &-button {
        position: relative;
        font-size: 1em;
        font-weight: bold;
        padding: 0.8em 1.2em;
        margin: 0;
        text-transform: none;
        border: 0;
        outline: 0;
        transition: var(--animationBase);
        cursor: pointer;
        white-space: nowrap;
        text-align: center;
        text-decoration: none;
        z-index: 1;

        &:hover {
            text-decoration: none;
        }

        &--disableCookies {
            background: var(--colorSecondaryBg);
            color: text-contrast(var(--colorSecondaryBg));

            &:hover {
                background: lightness(var(--colorSecondaryBg), .88);
            }
        }

        &--allowCookies {
            background: var(--colorBrand);
            color: text-contrast(var(--colorBrand));

            @media (max-width: 40rem) {
                order: -1;
            }

            &:hover {
                background: lightness(var(--colorBrand), .88);
                color: text-contrast(var(--colorBrand));
            }
        }
    }

    &-linkButton {
        flex: 1 0 100%;
        font-weight: bold;
        text-align: center;
        outline: 0;
        border: 0;
        cursor: pointer;
        background: transparent;
        margin: .5em 0 -.5em;
        padding: .125rem .25rem;
        color: inherit;
        transition: var(--animationBase);

        &--faded {
            opacity: .48;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    &-icon {
        position: absolute;
        top: -.75rem;
        right: -.75rem;
        z-index: var(--layerNegativeZIndex);
        opacity: .05;
        transform: rotate(188deg);
        pointer-events: none;

        @media (max-width: 40rem) {
            width: 6.4rem;
            height: 6.4rem;
        }
    }
}